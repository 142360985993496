// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Playbook_playbook-page__LN2Tt {\r\n\tmargin-left: 12rem;\r\n\tmargin-top:  1rem;\r\n}\r\n\r\n.Playbook_select_btn__ajp3A {\r\n\tfont-size: 36px;\r\n\tmargin-right: 10px;\r\n}\r\n\r\n.Playbook_winner_btn__Vx8qA {\r\n\tbackground-color: red;\r\n}\r\n\r\n.Playbook_podium_btn__tyGFq {\r\n\tbackground-color: yellow;\r\n}\r\n\r\n.Playbook_topsix_btn__NHcRT {\r\n\tbackground-color: green;\r\n}", "",{"version":3,"sources":["webpack://./src/pages/Playbook.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,iBAAiB;AAClB;;AAEA;CACC,eAAe;CACf,kBAAkB;AACnB;;AAEA;CACC,qBAAqB;AACtB;;AAEA;CACC,wBAAwB;AACzB;;AAEA;CACC,uBAAuB;AACxB","sourcesContent":[".playbook-page {\r\n\tmargin-left: 12rem;\r\n\tmargin-top:  1rem;\r\n}\r\n\r\n.select_btn {\r\n\tfont-size: 36px;\r\n\tmargin-right: 10px;\r\n}\r\n\r\n.winner_btn {\r\n\tbackground-color: red;\r\n}\r\n\r\n.podium_btn {\r\n\tbackground-color: yellow;\r\n}\r\n\r\n.topsix_btn {\r\n\tbackground-color: green;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playbook-page": "Playbook_playbook-page__LN2Tt",
	"select_btn": "Playbook_select_btn__ajp3A",
	"winner_btn": "Playbook_winner_btn__Vx8qA",
	"podium_btn": "Playbook_podium_btn__tyGFq",
	"topsix_btn": "Playbook_topsix_btn__NHcRT"
};
export default ___CSS_LOADER_EXPORT___;
