import {useState} from 'react';
import PlaybookList from '../components/playbook/PlaybookList';
import styles from './Playbook.module.css';

const Playbook = () => {
    const[betType, setBetType] = useState('winner');

	const selectBetTypeHandler = (betType) => {
		console.log('Playbook->.selectBetTypeHandler, betType: ', betType);
		setBetType(betType);
	}
	let winnerBtnStyle = styles.select_btn + styles.winner_btn;
	const btnClickHandler = (target) => {
		 console.log('click. Target: ', target.target.id);
		 if(target.target.id === 'winnerBtn') {
		 	setBetType('winner');
		 }
		 if(target.target.id === 'podiumBtn') {
		 	setBetType('podium');
		 }
		 if(target.target.id === 'topSixBtn') {
		 	setBetType('topSix');
		 }
	}
	return (
		<div className={styles['playbook-page']}>
			<button onClick={btnClickHandler} 
				id="winnerBtn" 
				className={`${styles['select_btn']} ${betType == 'winner' && styles['winner_btn']}`}>
					Winner
			</button>
			<button 
				onClick={btnClickHandler} 
				id="podiumBtn" 
				className={`${styles['select_btn']} ${betType == 'podium' && styles['podium_btn']}`}>
					Podium
			</button>
			<button 
				onClick={btnClickHandler} 
				id="topSixBtn" 
				className={`${styles['select_btn']} ${betType == 'topSix' && styles['topsix_btn']}`}>
					Top six
			</button>
			<PlaybookList betType={betType} />
		</div>
	)
}

export default Playbook;