import React, {Fragment} from 'react';
import styles from './PlaybookListRow.module.css';

const PlaybookListRow = (props) => {
//	console.log('PlaybookListRow, props: ', props);
	let minBetOdds = 0;
	let maxBookOdds = 0;
	let bookBetSize = 0;
	if(props.betType === 'winner') {
		//console.log('it is winner');
		minBetOdds = props.driverPack.oddsPack.winMinBet;
		maxBookOdds = props.driverPack.oddsPack.winMaxBook;
		bookBetSize = props.driverPack.oddsPack.winBookMax;
	}
	else if(props.betType === 'podium') {
		//console.log('it is podium');
		minBetOdds = props.driverPack.oddsPack.podiumMinBet;
		maxBookOdds = props.driverPack.oddsPack.podiumMaxBook;
		bookBetSize = props.driverPack.oddsPack.podiumBookMax;
	}
	else if(props.betType === 'topSix') {
		//console.log('it is topSix');
		minBetOdds = props.driverPack.oddsPack.topSixMinBet;
		maxBookOdds = props.driverPack.oddsPack.topSixMaxBook;
		bookBetSize = props.driverPack.oddsPack.topSixBookMax;
	}
	else {

	}

	return (
		<tr className={styles['playbook-list-row']}>
			<td className={styles['playbook-list-row-name']}>{props.driverPack.driver.name}</td>
			<td className={styles['playbook-list-row-bet']}>{minBetOdds}</td>
			<td className={styles['playbook-list-row-book']}>{maxBookOdds}</td>
			<td className={styles['playbook-list-row-betsize']}>({bookBetSize})</td>
		</tr>
	)
}

export default PlaybookListRow;