// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlaybookListRow_playbook-list-row__YVqyt {\r\n\tpadding-bottom: 5px;\r\n\tfont-size: 1.2rem;\r\n}\r\n.PlaybookListRow_playbook-list-row-name__8e\\+aJ {\r\n\tpadding-right: 10px;\r\n}\r\n.PlaybookListRow_playbook-list-row-bet__UXW8a {\r\n\tpadding-left: 15px;\r\n\tpadding-right: 5px;\r\n\ttext-align: right;\r\n\tbackground-color: #a6d8ff;\r\n}\r\n\r\n.PlaybookListRow_playbook-list-row-book__2iXhs {\r\n\tpadding-left: 15px;\r\n\tpadding-right: 5px;\r\n\ttext-align: right;\r\n\tbackground-color: #fac9d4;\r\n}\r\n\r\n.PlaybookListRow_playbook-list-row-betsize__oaaMn {\r\n\tpadding-left: 15px;\r\n\tpadding-right: 5px;\r\n\ttext-align: right;\r\n\tfont-style: italic;\r\n}", "",{"version":3,"sources":["webpack://./src/components/playbook/PlaybookListRow.module.css"],"names":[],"mappings":"AAAA;CACC,mBAAmB;CACnB,iBAAiB;AAClB;AACA;CACC,mBAAmB;AACpB;AACA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,iBAAiB;CACjB,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,iBAAiB;CACjB,yBAAyB;AAC1B;;AAEA;CACC,kBAAkB;CAClB,kBAAkB;CAClB,iBAAiB;CACjB,kBAAkB;AACnB","sourcesContent":[".playbook-list-row {\r\n\tpadding-bottom: 5px;\r\n\tfont-size: 1.2rem;\r\n}\r\n.playbook-list-row-name {\r\n\tpadding-right: 10px;\r\n}\r\n.playbook-list-row-bet {\r\n\tpadding-left: 15px;\r\n\tpadding-right: 5px;\r\n\ttext-align: right;\r\n\tbackground-color: #a6d8ff;\r\n}\r\n\r\n.playbook-list-row-book {\r\n\tpadding-left: 15px;\r\n\tpadding-right: 5px;\r\n\ttext-align: right;\r\n\tbackground-color: #fac9d4;\r\n}\r\n\r\n.playbook-list-row-betsize {\r\n\tpadding-left: 15px;\r\n\tpadding-right: 5px;\r\n\ttext-align: right;\r\n\tfont-style: italic;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playbook-list-row": "PlaybookListRow_playbook-list-row__YVqyt",
	"playbook-list-row-name": "PlaybookListRow_playbook-list-row-name__8e+aJ",
	"playbook-list-row-bet": "PlaybookListRow_playbook-list-row-bet__UXW8a",
	"playbook-list-row-book": "PlaybookListRow_playbook-list-row-book__2iXhs",
	"playbook-list-row-betsize": "PlaybookListRow_playbook-list-row-betsize__oaaMn"
};
export default ___CSS_LOADER_EXPORT___;
