import React, {useState, useEffect, useCallback} from 'react';
import PlaybookListRow from './PlaybookListRow';
import styles from './PlaybookList.module.css';

const PlaybookList = (props) => {
	console.log('PlaybookList, props: ', props);
	const[driverPackList, setDriverPackList] = useState([]);
    const[error, setError] = useState(null);
    const[isLoading, setIsLoading] = useState(false);

    const fetchDriverPackListHandler = useCallback( async() => {
    	console.log('refreshing driver pack list');
        try {
            setError(null);
            setIsLoading(true);
            console.log('calling');
            const response = await fetch('https://paddock.centipod.se/playbook', 
                                         {method: "GET",
                                          headers: {"foo":"bar"},
                                          credentials: "omit"});
            if(!response.ok) {
                throw new Error('There was an error: ' + response.status);
            }
            console.log('ok');
            console.log('headers');
              console.log([...response.headers.entries()])

              // look for set-cookie
              // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
            console.log('headers Set-Cookie');
              console.log(response.headers.get("Set-Cookie"));
            const responseData = await response.json();
            setIsLoading(false);
            console.log('responseData: ', responseData);
            console.log('cookies: ', document.cookie);
            setDriverPackList(responseData);
        }
        catch(error) {
            console.log('error: ', error);
            setIsLoading(false);
            setError(error.message);
            setDriverPackList([]);
        }
    }, []);

    useEffect(() => {
        console.log('in useEffect(), calling fetchDriverPackListHandler');
        fetchDriverPackListHandler();
    }, []);

	return (
		<table className={styles['playbook-list']}>
	      {driverPackList.map((driverPack) => (
	        <PlaybookListRow
	          key={driverPack.driver.gridPosition}
	          driverPack={driverPack}
              betType={props.betType}
	        />
	      ))}
		</table>
	)
}

export default PlaybookList;